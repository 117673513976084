<template>
  <div class="dropdown-container" v-if="Object.keys(wallet).length > 0">
    <div class="selected-item" @click="toggleDropdown">
      <img v-if="selectedWallet" :src="getTokenIcon(selectedWallet.tokenId)" :alt="selectedWallet.tokenName"
        class="token-icon">
      <span style="text-transform: uppercase;">
        <number 
          v-if="selectedWallet" 
          :value="selectedWallet ? (selectedWallet.amount ? Number(selectedWallet.amount).toFixed(3) : 0) : 0"
          :animate="animate"
          >
        </number>
        {{ selectedWallet ? selectedWallet.tokenName : '' }}

      </span>
      <span style="width:10px"></span>
      
      <i class="arrow"></i>
    </div>
    <div v-if="dropdownOpen" class="dropdown-list" style="text-transform: uppercase;">
      <div v-for="(token, tokenId) in wallet" :key="tokenId" class="dropdown-item" @click.stop="selectToken(tokenId)">
        <img :src="getTokenIcon(tokenId)" :alt="token.tokenNambe" class="token-icon">
        <span>{{ token.amount ? Number(selectedWallet.amount).toFixed(3) : '0' }} {{ token.tokenName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Number from './AnimateNumber.vue';

export default {
  components: {
    number: Number
  },
  data() {
    return {
      dropdownOpen: false,
      animate: true,
    };
  },
  computed: {
    ...mapGetters(['wallet', 'token']),
    selectedWallet() {
      // console.log('.........................->',this.wallet)
      return this.wallet[this.token];
    }
  },
  // watch: {
  //   wallet: {
  //     handler(newWallet, oldWallet) {
  //       console.log('Wallet updated:', oldWallet, newWallet);
  //       // You can add any additional logic you need here
  //     },
  //     deep: true // This ensures that nested changes within the wallet object are also detected
  //   }
  // },    
  methods: {
    ...mapMutations(['setToken']),
    getTokenIcon(tokenId) {
      return `images/${tokenId}.png`; // Use provided image path
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectToken(tokenId) {
      this.dropdownOpen = false;
      this.selectedToken = tokenId;
      this.setToken(tokenId);
      this.animate = false;
      setTimeout(() => {
        this.animate = true;
      }, 100);
    }
  },
  mounted() {
    // this.selectedToken = this.token;
  },
};
</script>

<style scoped>
h2 {
  color: #ffffff;
  /* Match header color */
}

.dropdown-container {
  position: relative;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  min-width: 250px;
  margin-left: 10px;


}

.selected-item {
  display: flex;
  align-items: center;
  color: white;
  font-family: inherit;

  font-size: 16px;
  color: #f0f0f0;
  border: none;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;

  background-color: #444;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);
}

.arrow {
  margin-left: auto;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}

.dropdown-list {
  position: absolute;
  width: 100%;
  background-color: #3a3a3a;
  border: 1px solid #444444;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8); /* Deeper shadow for nav bar */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #555555;
}

.token-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.wallet-details {
  display: flex;
  align-items: center;
}
</style>
