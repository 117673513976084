<template>
  <div class="game-list">
    <h1>Available Games</h1>
    <div class="games-container">
      <div v-for="game in games" :key="game.name" class="game-item" @click="openGame(game.name)">
        <img 
          :src="getGameImage(game.name)" 
          :alt="game.name" 
          class="game-image"
          @error="setDefaultImage"
        />
        <p class="game-title">{{ game.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/lib/api";

export default {
  data() {
    return {
      games: [],
      fallbackImage: "/images/undefined.png", // Define a default image path
    };
  },
  async created() {
    try {
      const response = await api.get("/game/list");
      this.games = response.data;
    } catch (error) {
      console.error("Error fetching games:", error);
    }
  },
  methods: {
    getGameImage(gameName) {
      return `/images/games/${gameName}.png`; // Uses public folder images
    },
    openGame(gameName) {
      this.$router.push(`/games/${gameName}`);
    },
    setDefaultImage(event) {
      event.target.src = this.fallbackImage; // Replace with the fallback image when missing
    }
  }
};
</script>

<style scoped>
.game-list {
  padding: 20px;
  /* text-align: center; */
}

.games-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  gap: 20px;
}

.game-item {
  width: 50px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.game-item:hover {
  transform: scale(1.05);
}

.game-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.game-title {
  margin-top: 10px;
  font-size: 18px;
  color: white;
}
</style>
