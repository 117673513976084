<template>
    <div class="game-view">
      <h1>Playing: {{ gameName }}</h1>
      <GameIframe :gameName="gameName" />
    </div>
  </template>
  
  <script>
  import GameIframe from "@/components/GameIframe.vue";
  
  export default {
    components: {
      GameIframe,
    },
    props: {
      gameName: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .game-view {
    padding: 20px;
  }
  </style>
  