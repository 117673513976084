<template>
    <div class="iframe-container">
      <iframe
        v-if="gameUrl"
        :src="gameUrl"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  
  export default {
    props: {
      gameName: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapGetters(["authToken", "token"]),
      gameUrl() {
        if (!this.authToken || !this.token) return "";

        let baseGameUrl = process.env.VUE_APP_GAME_FRONTEND_URL;
   
        if (process.env.NODE_ENV === "development") {
            const ports = JSON.parse(process.env.VUE_APP_GAME_PORTS || "{}");
            const gamePort = ports[this.gameName];
            baseGameUrl = `${baseGameUrl}:${gamePort}`;
        } else {
            baseGameUrl = baseGameUrl.replace("{game}", this.gameName);
        }

        // Construct the dynamic game URL
        // const baseGameUrl = process.env.VUE_APP_GAME_FRONTEND_URL.replace("{game}", this.gameName);
        
        return `${baseGameUrl}/?game=${this.gameName}&session=${this.authToken}&token=${this.token}`;
      },
    },
  };
  </script>
  
  <style scoped>
  .iframe-container {
    width: 100%;
    height: 100%;
  }
  
  iframe {
    width: 100%;
    height: 100vh;
  }
  </style>
  