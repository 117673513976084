import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authToken: localStorage.getItem('casino.scgames.authToken'),
    wallet: {},
    profile: {},
    token: localStorage.getItem('casino.scgames.token'),
  },
  mutations: {
    setAuthToken(state, authToken) {
      state.authToken = authToken;
      localStorage.setItem('casino.scgames.authToken', authToken);
    },
    cleartAuthToken(state) {
      state.authToken = null;
      localStorage.removeItem('authToken');
      Vue.$toast.info('Logged out');
    },
    setWallet(state, wallet) {
      state.wallet = wallet;
    },
    clearWallet(state) {
      state.wallet = {};
    },
    setBalance(state, { token, amount }) {
      state.wallet[token].amount = amount;
    },
    setGasPrice(state, { chain, value }) {
      Object.keys(state.wallet).forEach(tokenId => {
        const token = state.wallet[tokenId];
        if (token.chainId === chain) {
          token.gasPrice = value;
        }
      });
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    clearProfile(state) {
      state.profile = {};
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('casino.scgames.token', token);
    },
    clearToken(state) {
      state.token = null;
      localStorage.removeItem('token');
    },
     
  },
  getters: {
    authToken: (state) => state.authToken,
    isUser: (state) => !!state.authToken,
    wallet: (state) => state.wallet,
    isWallet: (state) => Object.keys(state.wallet).length > 0,
    profile: (state) => state.profile,
    isProfile: (state) => Object.keys(state.profile).length > 0,
    token: (state) => {
      if (state.token && state.token in state.wallet) {
        return state.token;
      } else if (Object.keys(state.wallet).length > 0) {
        return Object.values(state.wallet)[0].tokenId;
      }
      return null;
    },    
  },
});
