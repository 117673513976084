import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import UserAccount from '../views/UserAccount.vue'
import GameList from '../views/GameList.vue'
import GameView from '../views/GameView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/account',
        name: 'Account',
        component: UserAccount
    },
    {
        path: '/games',
        name: 'GameList',
        component: GameList
    },
    {
        path: '/games/:gameName',
        name: 'GameView',
        component: GameView,
        props: true
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
