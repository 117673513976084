<template>
    <div id="app">
      <div id="nav">
        <router-link class="link" to="/">Home</router-link>
        <router-link class="link" to="/games">Games</router-link>
        <router-link class="link" v-if="isUser" to="/account">Account</router-link>
        <wallets></wallets>
        <login></login>
      </div>
      <router-view />
    </div>
</template>
  
<script>
// App.vue
import { mapGetters } from 'vuex';
import Login from './components/Login.vue';
import WalletDropdown from './components/WalletDropdown.vue';

export default {
  name: 'App',
  components: {
    login: Login,
    wallets: WalletDropdown
  },
  computed: {
    ...mapGetters(['isUser']),
  },
};
</script>

<style>
    body{
        background-color: rgb(53, 53, 53)!important;
        font-family: 'Verdana', sans-serif;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 5); /* Adds a subtle shadow */

        color: rgb(238, 238, 238);
    }
    .page {
        padding-left:15px;
    }
    .container {
        background-color: #2d2d2d;
        /* Match background color */
        color: #ffffff;
        /* Match text color */
        padding: 0px;
        border-radius: 8px;
        margin-bottom: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Deeper shadow for nav bar */
        max-width: 700px;
    }
    .content {
        padding-left:15px;
        padding-bottom:15px;
    }
    h2, h1 {
        margin: 5px;
    }
    h1 {
        margin-bottom: 15px
    }
    h2 {
        font-size: 20px;
        margin: 2px; /* Remove default margins */
        padding: 8px; /* Add padding inside the title */
        background-color: #444; /* Set a background color */
        color: white; /* Set the text color */
        width: 100%; /* Ensure it fills the parent container */
        box-sizing: border-box; /* Include padding in the element's width */
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Deeper shadow for nav bar */
    }    
    button{
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 20px;
    }
    input, select {
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        width: 100px;
        font-size: 16px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 5); /* Adds a subtle shadow */

    }

    button, input, select {
        font-family: inherit;
        font-size: 16px;
        background-color: #444; /* Dark background for form elements */
        color: #f0f0f0; /* Text color for form elements */
        border: none; /* Style adjustment for a cleaner look */
        padding: 10px; /* Spacing inside buttons and inputs */
        border-radius: 5px; /* Rounded corners */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
        transition: background-color 0.3s; /* Smooth transition for background color */
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 5); /* Adds a subtle shadow */

    }
    button:hover, input[type="button"]:hover, select:hover {
        background-color: #555; /* Slightly lighter on hover for feedback */
        cursor: pointer; /* Change cursor to indicate clickable */
    } 

    .link {
        color: #f0f0f0; /* Text color */
        text-decoration: none;
        padding: 10px 15px; /* Padding for clickable area */
        border-radius: 5px; /* Rounded corners for links */
        transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */

        text-align: center; /* Center the text inside the link */
        white-space: nowrap; /* Prevents the text from wrapping */
        overflow: hidden; /* Keeps the content from spilling out */
        text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */

    }

    .link:hover {
        background-color: #555; /* Background color on hover */
        color: #ffa500; /* Text color on hover */
    }


    #nav {
        display: flex; /* Use flexbox for horizontal alignment */
        justify-content: left; /* Evenly space out the links */
        align-items: center; /* Center items vertically */
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 5px; /* Rounded corners for nav bar */
        background-color: #444; /* Dark background for nav bar */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Deeper shadow for nav bar */
        flex-wrap: wrap;
    }

    .router-link-exact-active, .link.router-link-exact-active {
        color: #4A90E2; /* A professional shade of blue */
        background-color: #444; /* A slightly darker background for active link */
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3); /* Adjusted shadow for depth */
    }

    .wrapper {
        display: flex; /* Use flexbox to manage the layout */
        flex-direction: column; /* Stack children vertically */
        align-items: flex-start; /* Align children to the start (left) of the container */
        gap: 10px; /* Add space between sections */
    }
    .section {
        padding: 15px;
        background-color: #222; /*#222*/
        border: 1px solid #2a2a2a;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        width:auto;
        display: inline-block;
        float: none;
    }

    .title {
        display: block;
        background-color: #333;
        color: #dcdcdc; /* Subdued white for a softer look */
        padding: 10px;
        margin: -15px -15px 15px -15px; /* Align with section padding */
        border-bottom: 1px solid #444;
    }

    table {
        margin-top: 10px;
        width: auto; /* Let the table take the necessary space */
        margin-left: 0; /* Align table to the left */
        margin-right: 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Deeper shadow for nav bar */
    }

    td {
        padding: 5px 5px;
        color: #b0b0b0; /* Light grey for less contrast */
        border-bottom: 1px solid #2a2a2a;
        white-space: nowrap;        
    }

    td:first-child {
        color: #9dbcd4; /* Soft blue for key columns */
        font-weight: normal;
    }

    td:last-child {
        color: #e8e8e8; /* Brighter but not pure white */
    }


    .login {
        margin-left: auto
    }
    .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .dialog {
    background: #2d2d2d;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  }
  .dialog .content {
    padding:15px;
  }
  .dialog h3 {
    color: #ffffff;
        font-size: 20px;
        margin: 2px; /* Remove default margins */
        padding: 8px; /* Add padding inside the title */
        background-color: #444; /* Set a background color */
        color: white; /* Set the text color */
        width: 100%; /* Ensure it fills the parent container */
        box-sizing: border-box; /* Include padding in the element's width */
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Deeper shadow for nav bar */
  }
  .dialog p {
    margin: 0 0 10px;
    color: #cccccc;
  }
  .dialog-input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #3a3a3a;
    color: #ffffff;
  }
  .dialog-buttons {
    display: flex;
    justify-content: space-between;
  }
  .dialog-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .dialog-button.confirm {
    background-color: #28a745;
    color: white;
  }
  .dialog-button.cancel {
    background-color: #dc3545;
    color: white;
  }

</style>
