<template>
    <div class="page"> 
      <h1>Home</h1>
    </div>
</template>
  

<script>



export default {
}
</script>

<style scoped>


</style>
